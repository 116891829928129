.gender-row {
  display: flex;
  align-items: center !important;
  justify-content: space-around !important;
  padding: 1rem !important;
  flex-flow: row nowrap !important;
  &.centered-icons{
    .male{
      margin-left: 20px;
    }
    .female{
      margin-right: 20px;
    }
    .gender-row__item {
       flex: 0 1 auto;
    }
  }
}

.gender-row__item {
  display: flex;
  background: transparent;
  border: none;
  flex: auto;
  box-shadow: none;

  i {
    color: $vmDarkGrey;
    font-size: 30px;
    line-height: 30px;
    width: 12px;
    flex: 0 1 20px;
  }

  .label {
    flex: 0 1 auto;
    background: transparent;

    @include vm-label--large;
  }
}
