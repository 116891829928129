@mixin h1-font {
  font-family: "Lato", Arial;
  color: $vmDarkBlue;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 0 !important;
  margin-right: 25px;
  margin-bottom: 0 !important;

  @media only screen and (min-width: 768px) {
    float: left;
  }

  @media only screen and (max-width: 768px) {
    font-size: 25px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@mixin h2-font {
  font-family: "Lato", Arial;
  color: $vmDarkBlue;
  font-size: 20px;
  text-transform: uppercase;
}

@mixin vm-label {
  font-family: "Lato", Arial;
  color: $vmDarkGrey;
  font-size: 15px;
  background: none;
}

@mixin vm-label--large {
  font-family: "Lato", Arial;
  color: $vmMediumGrey;
  line-height: 30px;
  font-weight: 300;
  padding: 0;
  font-size: 26px;
}

@mixin vm-button {
  font-family: "Lato", Arial;
  color: #fff;
  background: $vmDarkBlue;
  border: 1px solid $vmDarkBlue;
  font-size: 14px;
  text-transform: capitalize;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  padding: 10px 20px;
  &:hover {
    color: $vmDarkBlue;
    background: #fff;
  }
}
