
.audience-by-week {
  .recharts-xAxis,
  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-axis-line {
    display: none;
  }

  .recharts-cartesian-axis-tick-value {
    fill: $vmDarkGrey; // Text colour
    font-size: 12px;
  }

  .recharts-default-tooltip {
    background-color: $vmDarkGrey !important;
    color: $white;
    border-color: transparent !important;
  }

  .recharts-tooltip-item {
    text-transform: capitalize;
    color: $white !important;
  }
}

#audience-by-week {
  @media (max-width: $small-monitor-max) {
    margin-bottom: 15px;
  }
}
