.footer {
	background: $vmDarkBlue;
	padding: 15px !important;

	.row {
		padding-bottom: 25px;
		p {
			color: $white;
		}
	}
}
