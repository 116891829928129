.filter-list-checkbox {
  color: $vmDarkGrey;
  font-size: 14px;
  margin-bottom: 5px;

  &.hidden {
    display: none;
  }

  label {
    font-size: inherit !important;
    color: inherit !important;

    &::before {
      border-radius: 3px;
    }

    &::after {
      color: $vmDarkBlue !important;
    }
  }
}

.filter-list-checkbox__category {
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-top: 10px;
}
