.options-wrapper {
  height: 100%;
  align-items: center;
  display: flex !important;
  flex-flow: column;
  margin-left: 1rem;
  margin-right: 1rem;

  @media only screen and (min-width: 1192px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
    margin-left: -1rem !important;
    margin-right: -1rem !important;

    .grid {
      width: 100% !important;
    }
  }
}

.options-wrapper__dashboard-item {
  border: 2px solid $vmDarkBlue;
  margin: 25px;
  padding: 0 !important;

  a {
    display: block;
    padding: 25px;

    h2 {
      color: $vmDarkBlue;
    }

    img {
      max-height: 122px;
      height: auto;
      margin: 0 auto;
    }

    &:hover {
      background: $vmDarkBlue;

      img {
        filter: brightness(0) invert(1);
      }

      h2 {
        color: $white;
      }
    }
  }
}

// Force remove padding on $tablet-max
// Force bottom margin on $tablet-max
.ui.stackable.grid > .row > .column.options-wrapper__dashboard-item {
  @media (max-width: $tablet-max) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 15px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.options-wrapper__cinetam-logo {
  display: block;
  margin: 0 auto;
  padding-bottom: 50px;
  width: 400px;
  max-width: 100%;

  @media (max-width: $tablet-max) {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.options-wrapper__val-morgan-logo {
  display: block;
  margin: 0 auto;
  padding-top: 50px;
  width: 400px;
  max-width: 100%;

  @media (max-width: $tablet-max) {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
