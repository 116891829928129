.audience-by-genre-reach {
	.recharts-curve.recharts-pie-label-line {
		display: none;
	}
}

.audience-by-genre-reach__label {
	fill: $vmDarkGrey;
	font-size: 19px;
}

#audience-by-genre-reach,
#audience-by-genre-index{
	overflow: hidden;
	.recharts-yAxis .recharts-cartesian-axis-tick-line {
		display: none;
	}
}