.age-column {
  .label {
    background: transparent;
    font-size: 16px;
    font-family: "Lato";
    text-align: center;
    width: 100%;
    border-bottom: 1px solid $vmMediumGrey;
    padding: 14px;
    color: $vmMediumGrey;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 0;
  }
}
