.sidebar-date-column {
  .ui.grid {
    .row {
      padding-top: 10px;
      padding-bottom: 0px;
      .date-label {
        display: flex;
        font-family: "Lato";
        justify-content: center;
      }
      .date-input {
        padding-right: 0px;
        div,
        input {
          max-width: 100% !important;
        }
        .react-datepicker-popper {
          div {
            max-width: none !important;
          }
        }
      }
    }
  }
  &.weeks{
    font-weight:bold;
    text-align: center;
  }
}

.genre-index-tooltip {
  background: $vmDarkGrey;
  color: $white;
  padding: 10px;
  border-radius: 2px;
  border-color: transparent;
}
