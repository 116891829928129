.stat-values {
  @include vm-label--large;

  margin-bottom: 10px;
  display: flex;
}

.stat-values__item {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}
