#printing{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 100px);
    background: rgba(0,0,0,0.95); 
    z-index: 
    1000;
    display: none;
    align-items: center;
    justify-content: center;
    color: #fff;
}