.ui.segment.slider-wrapper{
    background: transparent;
    border: none;
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
}

#consumer-profiling{

  @media only screen and (min-width: 768px) {
    margin-top: -50px;
}
}

#selected-movie-name{
  display: none;
}
