.campaign-audience-content {
  @media (min-width: $tablet-min) {
    margin-bottom: 215px !important;
  }
}

.campaign-audience-message {
  margin-top: 10px;
  color: #4d4d4d;
  font-size: 14px;
}

.campaign-audience-placeholder {
  top: 32%;
}
