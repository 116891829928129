.ui.grid {
	&.two-col {
		max-height: 235px;
		overflow-y: scroll;
		margin-bottom: 15px;
		.row {
			padding-top: 2px;
			padding-bottom: 2px;
			text-transform: capitalize
		}
	}
}
