.filter-search__results {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 15px;
}

.filter-search__no-results {
  margin-top: 5px;
}
