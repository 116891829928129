@font-face {
  font-family: 'Lato';
  src: url('../../fonts/lato-bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/lato-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lato-bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/lato-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lato-bold.svg#svgFontName') format('svg'); /* Legacy iOS */
   font-weight: bold;
   font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/lato-hairline.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/lato-hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lato-hairline.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/lato-hairline.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lato-hairline.svg#svgFontName') format('svg'); /* Legacy iOS */
   font-weight: 100;
   font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/lato-light.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/lato-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lato-light.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/lato-light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lato-light.svg#svgFontName') format('svg'); /* Legacy iOS */
   font-weight: 200;
   font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/lato-regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/lato-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lato-regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/lato-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lato-regular.svg#svgFontName') format('svg'); /* Legacy iOS */
   font-weight: normal;
   font-style: normal;
}