.sidebar-wrapper {
  background: $vmSidebarBackground;
  border-right: 1px solid #eee;
  padding-left: 1.5rem !important;

  @media (max-width: $tablet-max) {
    padding-left: 0 !important;
    margin-left: 1rem;
    margin-bottom: 1rem !important;
  }
}

.sidebar-wrapper__button {
  width: 100%;
}

.sidebar-wrapper__search {
  width: 100%;
  .input{
    width: 100%;
    .prompt {
      width: 100%;
      border-radius: 3px !important;
    }
  }
}

.sidebar-wrapper__select-movie {
  width: 100%;
  min-width: 0 !important;
}

.main-content {
  padding-right: 2rem !important;
}

.filter-search-row {
  padding-bottom: 10px !important;
}

.filter-list-row {
  padding-top: 0 !important;
}

.filter-header {
  font-size: 14px;
  color: #4d4d4d;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding-top: 10px;
}
