.page-wrapper {
  min-height: calc(100vh - 119px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-bottom: 30px;
}

.dashboard-grid {
  padding-left: 15px !important;
  padding-right: 15px !important;

  @media only screen and (min-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .column {
      display: flex;
      flex-direction: column;

      &.no-flex {
        display: inline-block;
      }
    }
  }

  @media only screen and (min-width: 1192px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}

.dashboard-grid__header {
  background-color: rgb(246, 246, 246);
  padding: 15px;
  margin: -15px 0 15px 0;

  @media only screen and (min-width: 1192px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}

.demo-grid {
  .ui.segment {
    flex: 1;
  }
}

.pushable {
  min-height: auto !important;
  border-radius: 0 !important;
}

.pusher {
  height: 100%;
}

.ui.button {
  @include vm-button;
}

.page-header {
  background: $vmPageHeaderBackground;
  padding: 10px 5px 10px 5px !important;
  align-items: center !important;

  @media only screen and (max-width: 768px) {
    text-align: center !important;

    .ui.button {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}

.ui.grid .body-row {
  padding: 20px 5px;
}

.ui.grid .inner-row {
  padding: 1rem 1rem;
}

.chart-loader {
  width: 100px;
  margin: 0 auto;
  &.vm-mini {
    width: 50px;
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}

.ui.divider {
  border-color: $vmMediumGrey;
}

.capitalized {
  text-transform: capitalize;
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      background: none transparent !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      cursor: text;
      top: 0;
      left: 1px;
      width: 100%;
      outline: 0;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      padding: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
      transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease, width 0.1s ease;
      transition: box-shadow 0.1s ease, width 0.1s ease,
      -webkit-box-shadow 0.1s ease;
      padding: 9px;
    }
  }
}

.react-datepicker-wrapper {
  padding-right: 0 !important;

  @media (max-width: $small-monitor-max) {
    margin-right: 1rem;
    padding-right: 0 !important;
  }
}

.recharts-surface {
  overflow: visible !important;
}

.recharts-default-tooltip {
  background-color: $vmDarkGrey !important;
  color: $white;
  border-color: transparent !important;
}

.recharts-tooltip-item {
  text-transform: capitalize;
  color: $white !important;
}

.blur{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  opacity: 0.5;
}

.placeholder-text{
  z-index: 1000;
  width: 420px;
  position: absolute;
  left: 50%;
  margin-left: -210px;
  top: 25%;
  max-width: 100%;
  z-index: 100;
  text-align: center;
  color: #0e3151;
  text-transform: uppercase;
  font-size: 31px;
  line-height: 41px;
  display:none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  opacity: 1;
  background: rgba(14,49,81,0.7);
  padding: 25px;
  color: #fff;
  border-radius: 3px;
  &.blur{
   display: block; 
 }
}

.movie-poster{
  max-height: 235px;
  margin:0 auto;
}
svg{
  font-family: "Lato", Arial;
  g,text,tspan{
    font-family: "Lato", Arial;
  }
}