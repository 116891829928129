
$vmPageHeaderBackground: #f6f6f6;
$vmSidebarBackground: #fff;
$white: #fff;
$black: #000;
$vmLightBlue: #72d0ea;
$vmBlue: #0098ce;
$vmDarkBlue: #0e3151;
$vmGrey: #e2e2e2;
$vmMediumGrey: #b3b2b0;

$vmDarkGrey: #4d4d4d;

$vmGenderIconGrey: #201a1a;

// https://semantic-ui.com/elements/container.html - breakpoints
$extra-large-monitor-max: 1300px;
$large-monitor-min: 1200px;
$large-monitor-max: 1199px;
$small-monitor-min: 992px;
$small-monitor-max: 991px;
$tablet-min: 768px;
$tablet-max: 767px;
$mobile-min: 436px;
$mobile-max: 435px;

// z-index heights - define
$z-index-default: auto; // the default z-index value
$z-index-bump: 1; // use to bump an element slightly above normal elements
$z-index-navigation: 80; // nav should go over all page elements
$z-index-navigation-menu: 90; // menus inside nav should cover nav
$z-index-modal: 100; // modals should cover everything on screen
