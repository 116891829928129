.ftatv-donut {
	.recharts-pie-label-line {
		display: none !important;
	}

	.recharts-pie-label-text {
		font-size: 17px;
	}
	.recharts-legend-wrapper{
		    transform: translate(42%, -196%);

		@media only screen and (max-width: 767px) {
			    transform: translate(45%, -196%);
		}
		@media only screen and (max-width: 600px) {
			    transform: translate(42%, -196%);
		}
		@media only screen and (max-width: 425px) {
			    transform: translate(39%, -196%);
		}
	}
}
