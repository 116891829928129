.fta-tv-consumption-bar {
  text {
    font-size: 12px;
  }

  .recharts-legend-item-text {
    text-transform: capitalize;
  }

  .recharts-default-legend {
    text-align: left !important;
  }
}
