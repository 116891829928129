.audience-by-week-stacked {
  margin-top: -5px;

  .recharts-xAxis,
  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-axis-line {
    display: none;
  }

  .recharts-text.recharts-cartesian-axis-tick-value {
    fill: $vmDarkGrey;
  }

  .recharts-cartesian-axis-tick-value {
    fill: $vmDarkGrey; // Text colour
    font-size: 12px;
  }

  .recharts-text {
    font-size: 12px;
  }

  .recharts-default-tooltip {
    background-color: $vmDarkGrey !important;
    color: $white;
    border-color: transparent !important;
  }

  .recharts-tooltip-item {
    text-transform: capitalize;
    color: $white !important;
  }

  .total-audience-legend {
    position: relative;
    top: -20px;
  }
}

.audience-by-week-stacked__hidden {
  display: none;
}

.audience-by-week-stacked__y-header {
  fill: $vmDarkGrey;
  text-transform: uppercase;
}
