.ui.segment.main-nav {
  background: $vmDarkBlue;
  padding: 5px;
  border-radius: 0;

  .logos {
    padding-top: 5px;

    a {
      color: #fff;

      img {
        max-width: 200px;
        display: inline-block;
        margin-right: 10px;

        @media only screen and (max-width: 767px) {
          max-width: 170px;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 3px;
    }
  }

  .ui.menu {
    background: transparent;
    justify-content: flex-end;

    .item {
      padding: 0;

      .text,
      i {
        color: #fff;
      }

      a {
        padding: 10px 20px;
        color: #fff;
      }

      .bars {
        font-size: 30px;
        margin-top: 8px;
        &:hover{
          cursor:pointer;
        }
      }
    }

    .pointing.dropdown {
      padding: 10px 20px;

      .menu {
        background: $vmDarkBlue;
      }
    }
  }
}

.ui.segment.pushable > .menu {
  background: $vmDarkBlue;
}

.getting-started {
  color: $white;
}

.getting-started__dropdown {
  &.visible {
    margin-top: 7px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  &::after {
    visibility: hidden !important;
  }
}

.main-nav {
  border-color: transparent !important;
  box-shadow: none !important;
}

.main-nav__menu {
  border-color: transparent !important;
  box-shadow: none !important;
}

.mobile-cross-wrapper{
  width: 100%;
  background: transparent  !important;
  text-align: right !important;
  margin-bottom: 0 !important;
  padding-bottom: 1px !important;
  i{
    color: #fff;
    font-size: 30px;
    margin-top: 8px;
    &:hover{
      cursor:pointer;
    }
  }
}