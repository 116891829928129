.selected-filters__filter {
  margin-bottom: 5px !important;
  cursor: pointer;
}

// Hides titles of tags from the browser/page and displays when painting components onto PDF
.hide-from-page {
  display: none;
  margin: 10px 0 !important;
}
