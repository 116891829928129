.audience-by-movie {
  .recharts-cartesian-axis-line {
    display: none;
  }

  .recharts-xAxis {
    display: none;
  }

  .recharts-yAxis .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .recharts-cartesian-axis-tick-value {
    fill: $vmDarkGrey;
  }
}
