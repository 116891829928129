.filter-list-section {
  &.hidden {
    display: none;
  }

  .accordion {
    margin-top: 0 !important;
  }

  .content {
    padding-top: 0 !important;
  }

  .title {
    padding-top: 0 !important;
  }
}

.filter-list-section__children {
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
}

.filter-list-select__title {
  color: $vmDarkGrey;
  font-size: 14px;
}

.filter-list-section__icon--active {
  color: $vmDarkBlue;
}

.filter-list-section__icon {
  color: $vmDarkGrey;
}
