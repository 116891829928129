.total-audience {
  .recharts-yAxis .recharts-cartesian-axis-tick-line {
    transform: translateX(6px);
  }

  .recharts-xAxis .recharts-cartesian-axis-tick-line {
    transform: translateY(-5px);
  }

  .recharts-line-dot {
    stroke-width: 5px;
  }

  .recharts-dot {
    stroke: transparent;
  }

  .recharts-default-tooltip {
    background-color: $vmDarkGrey !important;
    color: $white;
    border-color: transparent !important;
  }

  .recharts-tooltip-item {
    text-transform: capitalize;
    color: $white !important;
  }

  .recharts-text.recharts-label {
    fill: $vmDarkGrey;
    transform: translate(21px, -2px);
    text-transform: uppercase;
  }

  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-axis-line {
    shape-rendering: crispEdges;
  }

  .recharts-text.recharts-cartesian-axis-tick-value {
    fill: $vmDarkGrey;
    font-size: 12px;
  }

  .total-audience-legend__weekend .total-audience-legend__icon {
    color: $vmDarkGrey;
  }
}

.total-audience__week,
.total-audience__weekend {
  .recharts-line-curve {
    stroke-width: 3px;
  }
}

.total-audience__week {
  .recharts-line-dot {
    fill: $vmBlue;
    stroke: $vmBlue;
  }
}

.total-audience__weekend {
  .recharts-line-dot {
    fill: $vmDarkGrey;
    stroke: $vmDarkGrey;
  }
}

#total-audience {
  @media (max-width: $small-monitor-max) {
    margin-bottom: 30px;
  }
}
