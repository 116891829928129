.total-audience-legend {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  padding-left: 47px;
  justify-content: flex-start;
  margin-top: 0 !important;
}

.total-audience-legend__item {
  text-transform: capitalize;
  padding: 0 10px;
}

.total-audience-legend__week {
  color: $vmBlue;

  .total-audience-legend__icon {
    color: inherit;
  }
}

.total-audience-legend__weekend {
  color: $vmDarkGrey;

  .total-audience-legend__icon {
    color: #e2e2e2;
  }
}
