.ui.grid .row{
	.consumer-profiling-wrapper{
		padding-left: 5px;
		padding-right: 5px;

		@media only screen and (max-width: 992px) {
			margin-bottom: 10px;
		}
		.consumer-profiling-tile{
			padding: 10px;
			font-size: 12px;
			-webkit-transition: all .1s ease;
			transition: all .1s ease;
			p:first-child{
				height: 40px;
			}
			p:last-child{
				font-size:17px;
			}
			@media only screen and (max-width: 992px) {
				display: block !important;
			}
		}
	}
}