h1.ui.header{
    @include h1-font;
}

h2.ui.header{
    @include h2-font;
}

.ui.label.vmLabel{
    @include vm-label;
}